<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="EprsOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <EprsListing></EprsListing>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import LoadingComponent from "Components/Appic/LoadingComponent";
import {mapFields} from "vuex-map-fields";

const EprsListing = () => ({
    component: import("Components/Appic/EprsListing"),
    loading: LoadingComponent
});

export default {
    name: "EprsOverview",
    components: {EprsListing},
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        newEpr () {
            let tab = window.open('/v1/eprs/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    }
}
</script>

<style scoped>

</style>